// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/metrics/SourceFormatterTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.formatter.SourceFormatterTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import {SanitizedHtml as goog_soy_data_SanitizedHtml} from 'ts-closure-library/lib/soy/data';
import * as google from 'ts-closure-library/lib/google';
import * as goog_html from 'ts-closure-library/lib/html/safehtml';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$findings$FindingsPerspectiveTemplate from './../findings/FindingsPerspectiveTemplate.soy.generated.js';
import * as $soy$ts$perspectives$user$TeamscaleUserPerspectiveTemplate from './../user/TeamscaleUserPerspectiveTemplate.soy.generated.js';


/**
 * @param {!$code.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $code = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.code']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.code'](opt_data, $ijData);
  }
  /** @type {!Array<!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string>} */
  const lines = soy.assertParamType(Array.isArray(opt_data.lines), 'lines', opt_data.lines, '@param', '!Array<!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string>');
  /** @type {!Array<string>} */
  const lineClasses = soy.assertParamType(Array.isArray(opt_data.lineClasses), 'lineClasses', opt_data.lineClasses, '@param', '!Array<string>');
  /** @type {number} */
  const firstUserVisibleLineNumber = soy.assertParamType(typeof opt_data.firstUserVisibleLineNumber === 'number', 'firstUserVisibleLineNumber', opt_data.firstUserVisibleLineNumber, '@param', 'number');
  /** @type {null|number|undefined} */
  const maxLinesPerContainer = soy.assertParamType(opt_data.maxLinesPerContainer == null || typeof opt_data.maxLinesPerContainer === 'number', 'maxLinesPerContainer', opt_data.maxLinesPerContainer, '@param', 'null|number|undefined');
  let $output = '';
  if (lines.length == 1 && (soy.$$equals(lines[/** @type {?} */ (0)], '') || soy.$$equals(lines[/** @type {?} */ (0)], ' '))) {
    $output += '<table class="prettyprint code"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.code ts/perspectives/metrics/SourceFormatterTemplate.soy:35"' : '') + '><tr class="line ' + soy.$$escapeHtmlAttribute(lineClasses[/** @type {?} */ (0)]) + '" data-line="' + soy.$$escapeHtmlAttribute(firstUserVisibleLineNumber) + '"><td class="linenumber"></td><td class="changeArea"></td><td class="markerArea"></td><td><i>Empty file</i></td></tr></table>';
  } else {
    if (!maxLinesPerContainer || maxLinesPerContainer == null) {
      $output += $codeSnippet(soy.$$assignDefaults({linesToRender: lines.length, startIndex: 0}, opt_data), $ijData);
    } else {
      $output += '<div class="grid grid-cols-1"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.code ts/perspectives/metrics/SourceFormatterTemplate.soy:51"' : '') + '>';
      const i6783_RangeEnd = lines.length;
      const i6783ListLen = Math.max(0, Math.ceil((i6783_RangeEnd - 0) / 1));
      for (let i6783Index = 0; i6783Index < i6783ListLen; i6783Index++) {
        const i6783Data = 0 + i6783Index * 1;
        $output += i6783Data % maxLinesPerContainer == 0 ? $codeSnippet(soy.$$assignDefaults({startIndex: i6783Data, linesToRender: maxLinesPerContainer, firstUserVisibleLineNumber: firstUserVisibleLineNumber + i6783Data, snippetIndex: i6783Data}, opt_data), $ijData) : '';
      }
      $output += '</div>';
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $code as code };
/**
 * @typedef {{
 *  lines: !Array<!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string>,
 *  lineClasses: !Array<string>,
 *  firstUserVisibleLineNumber: number,
 *  maxLinesPerContainer?: (null|number|undefined),
 *  codeFontSize?: (null|number|undefined),
 *  snippetIndex?: (null|number|undefined),
 * }}
 */
$code.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $code.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.code';
}


/**
 * @param {!$codeSnippet.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $codeSnippet = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $codeSnippet$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.lines, opt_data.lineClasses, opt_data.codeFontSize, opt_data.firstUserVisibleLineNumber, opt_data.startIndex, opt_data.linesToRender, opt_data.snippetIndex);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string>} lines
 * @param {!Array<string>} lineClasses
 * @param {number} codeFontSize
 * @param {number} firstUserVisibleLineNumber
 * @param {number} startIndex
 * @param {number} linesToRender
 * @param {null|number|undefined=} snippetIndex
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $codeSnippet$ = function($$areYouAnInternalCaller, $ijData, lines, lineClasses, codeFontSize, firstUserVisibleLineNumber, startIndex, linesToRender, snippetIndex) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.codeSnippet']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.codeSnippet']({lines: lines, lineClasses: lineClasses, codeFontSize: codeFontSize, firstUserVisibleLineNumber: firstUserVisibleLineNumber, startIndex: startIndex, linesToRender: linesToRender, snippetIndex: snippetIndex}, $ijData);
  }
  soy.assertParamType(Array.isArray(lines), 'lines', lines, '@param', '!Array<!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string>');
  soy.assertParamType(Array.isArray(lineClasses), 'lineClasses', lineClasses, '@param', '!Array<string>');
  soy.assertParamType(typeof codeFontSize === 'number', 'codeFontSize', codeFontSize, '@param', 'number');
  soy.assertParamType(typeof firstUserVisibleLineNumber === 'number', 'firstUserVisibleLineNumber', firstUserVisibleLineNumber, '@param', 'number');
  soy.assertParamType(typeof startIndex === 'number', 'startIndex', startIndex, '@param', 'number');
  soy.assertParamType(typeof linesToRender === 'number', 'linesToRender', linesToRender, '@param', 'number');
  soy.assertParamType(snippetIndex == null || typeof snippetIndex === 'number', 'snippetIndex', snippetIndex, '@param', 'null|number|undefined');
  let $output = '<table class="prettyprint code"' + (snippetIndex ? ' data-snippet="' + soy.$$escapeHtmlAttribute(snippetIndex) + '"' : '') + ' style="font-size: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(codeFontSize)) + 'px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.codeSnippet ts/perspectives/metrics/SourceFormatterTemplate.soy:84"' : '') + '>';
  const i6813ListLen = Math.max(0, Math.ceil((linesToRender - 0) / 1));
  for (let i6813Index = 0; i6813Index < i6813ListLen; i6813Index++) {
    const i6813Data = 0 + i6813Index * 1;
    const lineNumber__soy6815 = startIndex + i6813Data;
    if (lineNumber__soy6815 < lines.length) {
      const visibleLineNumber__soy6819 = firstUserVisibleLineNumber + i6813Data;
      $output += '<tr class="line ' + soy.$$escapeHtmlAttribute(lineClasses[/** @type {?} */ (lineNumber__soy6815)]) + ' code-line-' + soy.$$escapeHtmlAttribute(visibleLineNumber__soy6819) + ' relative" data-line="' + soy.$$escapeHtmlAttribute(visibleLineNumber__soy6819) + '"><td class="linenumber" style="width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(('' + ('' + (firstUserVisibleLineNumber + linesToRender))).length * 0.6)) + 'em">' + soy.$$escapeHtml(visibleLineNumber__soy6819) + '</td><td class="changeArea"></td><td class="markerArea"></td><td class="codeLine">' + soy.$$escapeHtml(lines[/** @type {?} */ (lineNumber__soy6815)]) + '</td></tr>';
    }
  }
  $output += '</table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  lines: !Array<!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string>,
 *  lineClasses: !Array<string>,
 *  codeFontSize: number,
 *  firstUserVisibleLineNumber: number,
 *  startIndex: number,
 *  linesToRender: number,
 *  snippetIndex?: (null|number|undefined),
 * }}
 */
$codeSnippet.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $codeSnippet.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.codeSnippet';
}


/**
 * @param {!$moreSnippetsMessage.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $moreSnippetsMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $moreSnippetsMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.moreCount);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number} moreCount
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $moreSnippetsMessage$ = function($$areYouAnInternalCaller, $ijData, moreCount) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.moreSnippetsMessage']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.moreSnippetsMessage']({moreCount: moreCount}, $ijData);
  }
  soy.assertParamType(typeof moreCount === 'number', 'moreCount', moreCount, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="more-code-snippets-message ui message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.moreSnippetsMessage ts/perspectives/metrics/SourceFormatterTemplate.soy:108"' : '') + '><i class="plus icon"></i>' + soy.$$escapeHtml(moreCount) + ' more</div>');
};
export { $moreSnippetsMessage as moreSnippetsMessage };
export { $moreSnippetsMessage$ as moreSnippetsMessage$ };
/**
 * @typedef {{
 *  moreCount: number,
 * }}
 */
$moreSnippetsMessage.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $moreSnippetsMessage.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.moreSnippetsMessage';
}


/**
 * @param {!$issueReferencesTable.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $issueReferencesTable = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueReferencesTable']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueReferencesTable'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {!Array<?>} */
  const issueReferences = soy.assertParamType(Array.isArray(opt_data.issueReferences), 'issueReferences', opt_data.issueReferences, '@param', '!Array<?>');
  /** @type {null|string|undefined} */
  const branch = soy.assertParamType(opt_data.branch == null || typeof opt_data.branch === 'string', 'branch', opt_data.branch, '@param', 'null|string|undefined');
  let $output = '<div class="header-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueReferencesTable ts/perspectives/metrics/SourceFormatterTemplate.soy:122"' : '') + '><span class="ui small header">Linked References</span><span class="ui circular small light label" id="linked-issue-reference-number">' + soy.$$escapeHtml(issueReferences.length) + '</span></div><div id="issue-references-table-container" style="margin-top: 10px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueReferencesTable ts/perspectives/metrics/SourceFormatterTemplate.soy:126"' : '') + '><table id="issue-references-table" data-testid="mark-issues-table" class="ui sortable selectable table striped borderless framed"><thead><tr><th class="two wide issue-reference-id">ID</th><th class="issue-reference-connector">Connector</th><th class="subject">Subject</th></tr></thead><tbody>';
  if (issueReferences) {
    const issueReference6992List = issueReferences;
    const issueReference6992ListLen = issueReference6992List.length;
    for (let issueReference6992Index = 0; issueReference6992Index < issueReference6992ListLen; issueReference6992Index++) {
      const issueReference6992Data = issueReference6992List[issueReference6992Index];
      $output += $issueTableRow(soy.$$assignDefaults({project: project, resolvedIssue: issueReference6992Data, issueType: issueReference6992Data.type, selectedBranch: branch}, opt_data), $ijData);
    }
  }
  $output += '</tbody></table></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $issueReferencesTable as issueReferencesTable };
/**
 * @typedef {{
 *  project: string,
 *  issueReferences: !Array<?>,
 *  branch?: (null|string|undefined),
 * }}
 */
$issueReferencesTable.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueReferencesTable.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.issueReferencesTable';
}


/**
 * @param {!$issueTableRow.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $issueTableRow = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueTableRow$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.resolvedIssue, opt_data.issueType, opt_data.selectedBranch);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {{issue: ?, issueUserResolution: ?,}} resolvedIssue
 * @param {string} issueType
 * @param {null|string|undefined=} selectedBranch
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $issueTableRow$ = function($$areYouAnInternalCaller, $ijData, project, resolvedIssue, issueType, selectedBranch) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueTableRow']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueTableRow']({project: project, resolvedIssue: resolvedIssue, issueType: issueType, selectedBranch: selectedBranch}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(google.isObject(resolvedIssue), 'resolvedIssue', resolvedIssue, '@param', '{issue: ?, issueUserResolution: ?,}');
  soy.assertParamType(typeof issueType === 'string', 'issueType', issueType, '@param', 'string');
  soy.assertParamType(selectedBranch == null || typeof selectedBranch === 'string', 'selectedBranch', selectedBranch, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<tr class="issue-row" data-issue-id="' + soy.$$escapeHtmlAttribute(resolvedIssue.issue.id.internalId) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueTableRow ts/perspectives/metrics/SourceFormatterTemplate.soy:165"' : '') + '><td>' + $issueTableIdLink$(soy.$$internalCallMarkerDoNotUse, $ijData, project, resolvedIssue.issue, issueType, selectedBranch) + '</td><td>' + soy.$$escapeHtml(resolvedIssue.issue.id.connectorId) + '</td><td><div class="ui middle aligned list"><div class="item"><div class="left floated content">' + $issueTableSubjectLink$(soy.$$internalCallMarkerDoNotUse, $ijData, project, resolvedIssue.issue, issueType, selectedBranch) + '</div><div class="right floated content">' + $soy$ts$commons$UIUtilsTemplate.issueStatusLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, resolvedIssue.issue, 'small', true) + ' ' + $soy$ts$perspectives$user$TeamscaleUserPerspectiveTemplate.roundAvatar$(soy.$$internalCallMarkerDoNotUse, $ijData, resolvedIssue.issue.assignee, resolvedIssue.issueUserResolution.resolvedAssignee, undefined, true, 'Assignee:') + '</div></div></div></td></tr>');
};
/**
 * @typedef {{
 *  project: string,
 *  resolvedIssue: {issue: ?, issueUserResolution: ?,},
 *  issueType: string,
 *  selectedBranch?: (null|string|undefined),
 * }}
 */
$issueTableRow.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueTableRow.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.issueTableRow';
}


/**
 * @param {!$issueTableIdLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $issueTableIdLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueTableIdLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.issue, opt_data.issueType, opt_data.selectedBranch);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} issue
 * @param {string} issueType
 * @param {null|string|undefined=} selectedBranch
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $issueTableIdLink$ = function($$areYouAnInternalCaller, $ijData, project, issue, issueType, selectedBranch) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueTableIdLink']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueTableIdLink']({project: project, issue: issue, issueType: issueType, selectedBranch: selectedBranch}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof issueType === 'string', 'issueType', issueType, '@param', 'string');
  soy.assertParamType(selectedBranch == null || typeof selectedBranch === 'string', 'selectedBranch', selectedBranch, '@param', 'null|string|undefined');
  let $tmp$$1;
  if (soy.$$equals(issue.url, '')) {
    let $tmp;
    if (issueType == 'Specification Item') {
      $tmp = '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.specItem$(soy.$$internalCallMarkerDoNotUse, $ijData, project, issue.id, undefined, {branchName: selectedBranch}))) + '" data-sort-value="' + soy.$$escapeHtmlAttribute(issue.id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueTableIdLink ts/perspectives/metrics/SourceFormatterTemplate.soy:212"' : '') + '>' + soy.$$escapeHtml(issue.id) + '</a>';
    } else if (issueType == 'Issue') {
      $tmp = '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.issue$(soy.$$internalCallMarkerDoNotUse, $ijData, project, issue.id, selectedBranch))) + '" data-sort-value="' + soy.$$escapeHtmlAttribute(issue.id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueTableIdLink ts/perspectives/metrics/SourceFormatterTemplate.soy:218"' : '') + '>' + soy.$$escapeHtml(issue.id) + '</a>';
    } else {
      $tmp = '';
    }
    $tmp$$1 = $tmp;
  } else {
    $tmp$$1 = '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(issue.url)) + '" target="_blank" data-sort-value="' + soy.$$escapeHtmlAttribute(issue.id.externalId) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueTableIdLink ts/perspectives/metrics/SourceFormatterTemplate.soy:226"' : '') + '><i class="external alternate icon"></i> ' + soy.$$escapeHtml(issue.id.externalId) + '</a>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($tmp$$1);
};
/**
 * @typedef {{
 *  project: string,
 *  issue: ?,
 *  issueType: string,
 *  selectedBranch?: (null|string|undefined),
 * }}
 */
$issueTableIdLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueTableIdLink.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.issueTableIdLink';
}


/**
 * @param {!$issueTableSubjectLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $issueTableSubjectLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueTableSubjectLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.issue, opt_data.issueType, opt_data.selectedBranch);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} issue
 * @param {string} issueType
 * @param {null|string|undefined=} selectedBranch
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $issueTableSubjectLink$ = function($$areYouAnInternalCaller, $ijData, project, issue, issueType, selectedBranch) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueTableSubjectLink']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.issueTableSubjectLink']({project: project, issue: issue, issueType: issueType, selectedBranch: selectedBranch}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof issueType === 'string', 'issueType', issueType, '@param', 'string');
  soy.assertParamType(selectedBranch == null || typeof selectedBranch === 'string', 'selectedBranch', selectedBranch, '@param', 'null|string|undefined');
  let $tmp;
  if (issueType == 'Specification Item' || issueType == 'SPEC_ITEM') {
    $tmp = (issue.typeInfo.typeAbbreviation ? '<span' + (issue.typeInfo.type ? soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, issue.typeInfo.type)) : '') + ' class="ui circular small light label">' + soy.$$escapeHtml(issue.typeInfo.typeAbbreviation) + '</span>' : '') + '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.specItem$(soy.$$internalCallMarkerDoNotUse, $ijData, project, issue.id, undefined, {branchName: selectedBranch}))) + '">' + soy.$$escapeHtml(issue.subject) + '</a>';
  } else if (issueType == 'Issue' || issueType == 'ISSUE') {
    $tmp = '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.issue$(soy.$$internalCallMarkerDoNotUse, $ijData, project, issue.id, selectedBranch))) + '">' + soy.$$escapeHtml(issue.subject) + '</a>';
  } else {
    $tmp = '';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="issue-link"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.issueTableSubjectLink ts/perspectives/metrics/SourceFormatterTemplate.soy:238"' : '') + '>' + $tmp + '</div>');
};
/**
 * @typedef {{
 *  project: string,
 *  issue: ?,
 *  issueType: string,
 *  selectedBranch?: (null|string|undefined),
 * }}
 */
$issueTableSubjectLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueTableSubjectLink.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.issueTableSubjectLink';
}


/**
 * @param {!$nonCode.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $nonCode = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $nonCode$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.html);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string} html
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $nonCode$ = function($$areYouAnInternalCaller, $ijData, html) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.nonCode']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.nonCode']({html: html}, $ijData);
  }
  soy.assertParamType(goog_soy_data_SanitizedHtml.isCompatibleWith(html) || typeof html === 'string', 'html', html, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<pre class="plaintext" style="margin: 0px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.nonCode ts/perspectives/metrics/SourceFormatterTemplate.soy:268"' : '') + '>' + soy.$$escapeHtml(html) + '</pre>');
};
export { $nonCode as nonCode };
export { $nonCode$ as nonCode$ };
/**
 * @typedef {{
 *  html?: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string),
 * }}
 */
$nonCode.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $nonCode.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.nonCode';
}


/**
 * @param {!$findingTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $findingTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.findings, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} project
 * @param {?} findings
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingTooltip$ = function($$areYouAnInternalCaller, $ijData, project, findings, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.findingTooltip']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.findingTooltip']({project: project, findings: findings, commit: commit}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '';
  const finding7215List = findings;
  const finding7215ListLen = finding7215List.length;
  for (let finding7215Index = 0; finding7215Index < finding7215ListLen; finding7215Index++) {
    const finding7215Data = finding7215List[finding7215Index];
    $output += $findingTooltipEntry$(soy.$$internalCallMarkerDoNotUse, $ijData, project, finding7215Data, commit) + '<br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.findingTooltip ts/perspectives/metrics/SourceFormatterTemplate.soy:291"' : '') + '>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $findingTooltip as findingTooltip };
export { $findingTooltip$ as findingTooltip$ };
/**
 * @typedef {{
 *  project: ?,
 *  findings: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$findingTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingTooltip.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.findingTooltip';
}


/**
 * @param {!$findingTooltipEntry.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $findingTooltipEntry = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingTooltipEntry$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.finding, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} project
 * @param {?} finding
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $findingTooltipEntry$ = function($$areYouAnInternalCaller, $ijData, project, finding, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.findingTooltipEntry']) {
    return soy.$$stubsMap['ts.commons.formatter.SourceFormatterTemplate.findingTooltipEntry']({project: project, finding: finding, commit: commit}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = $soy$ts$perspectives$findings$FindingsPerspectiveTemplate.findingIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, finding) + ' ' + $soy$ts$perspectives$findings$FindingsPerspectiveTemplate.findingLink$(soy.$$internalCallMarkerDoNotUse, $ijData, project, finding, commit);
  if (finding[/** @type {?} */ ('siblingLocations')]) {
    $output += '<ul class="ui list"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.SourceFormatterTemplate.findingTooltipEntry ts/perspectives/metrics/SourceFormatterTemplate.soy:316"' : '') + '>';
    const location7239List = finding[/** @type {?} */ ('siblingLocations')];
    const location7239ListLen = location7239List.length;
    for (let location7239Index = 0; location7239Index < location7239ListLen; location7239Index++) {
      const location7239Data = location7239List[location7239Index];
      $output += '<li>' + $soy$ts$perspectives$findings$FindingsPerspectiveTemplate.findingLocationLink$(soy.$$internalCallMarkerDoNotUse, $ijData, finding, project, commit, location7239Data) + ' ' + $soy$ts$perspectives$findings$FindingsPerspectiveTemplate.siblingComparisonLink$(soy.$$internalCallMarkerDoNotUse, $ijData, project, finding[/** @type {?} */ ('location')], location7239Data, commit, true) + '</li>';
    }
    $output += '</ul>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  project: ?,
 *  finding: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$findingTooltipEntry.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingTooltipEntry.soyTemplateName = 'ts.commons.formatter.SourceFormatterTemplate.findingTooltipEntry';
}
