import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, type JSX, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { Segment } from 'ts/components/Segment';
import { AnalysisStateInfo } from 'ts/base/AnalysisStateInfo';
import { useAsync } from 'ts/base/hooks/AsyncHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { DisposableKeyboardShortcutRegistry } from 'ts/base/scaffolding/DisposableKeyboardShortcutRegistry';
import { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { disposeAllOpenModals } from 'ts/commons/modal/ModalUtils';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { EventAnnouncementBanner } from 'ts/perspectives/admin/event-announcement/components/EventAnnouncementBanner';

/** Props for TeamscalePerspective. */
type TeamscaleViewWrapperProps = {
	view: TeamscaleViewBase;
	defaultBranchName: string | null;
	projectIds: string[];
	hash: NavigationHash;
	context: ExtendedPerspectiveContext;
};

/**
 * Wraps a TeamscalePerspectiveBase into React. It asynchronously loads the actual implementation of the perspective,
 * ensures Semantic UI is loaded, fetches the perspective context, instantiates the perspective and handles the
 * historyChanged lifecycle for the component.
 */
export function TeamscaleViewWrapper({
	view,
	defaultBranchName,
	projectIds,
	hash,
	context
}: TeamscaleViewWrapperProps): JSX.Element {
	const activePerspective = hash.getPerspective();
	const mainContentLoaded = useRef<boolean>(false);

	const mainContainerRef = useRef<HTMLDivElement | null>(null);
	const viewContentRef = useRef<HTMLDivElement | null>(null);

	const initAndPreloader = useCallback(() => {
		view.init(context, defaultBranchName, new DisposableKeyboardShortcutRegistry());
		return view.preloadContentAsync();
	}, [context, defaultBranchName, view]);
	const preloadResult = useAsync(initAndPreloader);

	useLayoutEffect(() => {
		if (preloadResult.status === 'success') {
			if (view.disposed) {
				// Happens when React tries to perform a hot reload of a component
				location.reload();
			}
			mainContentLoaded.current = true;
			const mainContainer = mainContainerRef.current!;
			view.renderInto(mainContainer, viewContentRef.current!, activePerspective, hash);
			// Focus the main container if no other element is focused to enable keyboard scrolling
			if (!document.hasFocus()) {
				mainContainer.focus();
			}
			return () => {
				mainContainer.style.display = '';
				view.dispose();
				tsdom.removeAllChildren(mainContainer);
				mainContainer.style.display = 'none';
			};
		}
		return;
	}, [hash, context, activePerspective, view, preloadResult.status]);
	return (
		<TeamscaleViewContent
			ref={mainContainerRef}
			viewDescriptor={view.viewDescriptor!}
			defaultBranchName={defaultBranchName}
			projectIds={projectIds}
		>
			<div ref={viewContentRef} style={{ display: 'contents' }} />
		</TeamscaleViewContent>
	);
}

/** Props for TeamscaleViewContent. */
type TeamscaleViewContentProps = {
	projectIds: string[];
	defaultBranchName: string | null;
	viewDescriptor: ViewDescriptor;
	children: ReactNode;
};

/**
 * Wrapper around a Teamscale view that displays the analysis state and event announcements above the given children. It
 * also ensures that any open closure dialogs are removed from the DOm when the view is unmounted and sets the default
 * document title.
 */
export const TeamscaleViewContent = forwardRef<HTMLDivElement, TeamscaleViewContentProps>(function TeamscaleViewContent(
	{ projectIds, defaultBranchName, viewDescriptor, children }: TeamscaleViewContentProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const event = useUserInfo().eventAnnouncement;
	useRemoveModalsOnUnmount();
	useDocumentTitle(viewDescriptor);
	return (
		<div id="ts-main-container" tabIndex={-1} ref={ref}>
			<Segment basic id="main" style={{ padding: viewDescriptor.hasSmallPadding ? '13px' : undefined }}>
				<EventAnnouncementBanner event={event} />
				<AnalysisStateInfo
					viewDescriptor={viewDescriptor}
					defaultBranchName={defaultBranchName}
					projectIds={projectIds}
				/>
				{children}
			</Segment>
			{/* Right sidebar will be inserted here */}
		</div>
	);
});

function useDocumentTitle(viewDescriptor: ViewDescriptor) {
	const hash = useNavigationHash();
	const perspective = hash.getPerspective();
	const project = hash.getProject();
	useEffect(() => {
		if (!viewDescriptor.hasSelfManagedTitle) {
			document.title = perspective.displayName;
			const viewTitle = viewDescriptor.name;
			if (viewTitle !== '') {
				document.title += TeamscaleViewBase.TITLE_SEPARATOR + viewTitle;
			}
			if (!StringUtils.isEmptyOrWhitespace(project)) {
				document.title += TeamscaleViewBase.TITLE_SEPARATOR + project;
			}

			document.title += TeamscaleViewBase.TITLE_SEPARATOR + 'Teamscale';
		}
	}, [perspective.displayName, project, viewDescriptor.hasSelfManagedTitle, viewDescriptor.name]);
}

/**
 * Removes any potential opened dialogs by removing their div elements on unmount. For more details on the Dialog HTML
 * structure, please refer to the {@code Dialog} class in the file: lib/ui/dialog.d.ts
 */
function useRemoveModalsOnUnmount() {
	useEffect(
		() => () => {
			tsdom.removeNodes(document.querySelectorAll('.modal-dialog'));
			tsdom.removeNodes(document.querySelectorAll('.modal-dialog-bg'));
			disposeAllOpenModals();
		},
		[]
	);
}
